import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { useFlexSearch } from "react-use-flexsearch"
import { Formik, Form, Field } from "formik"

const Search = ({ data }) => {
  const index = data.localSearchPages.index
  const store = data.localSearchPages.store

  const [query, setQuery] = useState(null)
  const results = useFlexSearch(query, index, store)

  return (
    <Layout>
      <Seo title="Search" description="Search the Alliance's web pages" />

      <section id="search-page" className="page-pad">
        <div className="page search-page">
          <h2>Search</h2>

          <div>
            <div className="column-main">
              <div className="search-field">
                <Formik
                  initialValues={{ query: "" }}
                  onSubmit={(values, { setSubmitting }) => {
                    setQuery(values.query)
                    setSubmitting(false)
                  }}
                >
                  <Form>
                    <Field name="query" placeholder="Site Search" />
                  </Form>
                </Formik>
              </div>

              {query ? <h1>Results for: {query}</h1> : null}

              {results.map(result => {
                return (
                  <div key={result.id}>
                    <h1>
                      <Link to={result.uri}>{result.title}</Link>
                    </h1>
                    <div>{parse(result.excerpt)}</div>
                  </div>
                )
              })}
            </div>
            <div className="column-side">
              <div className="box">
                <h4>Useful links</h4>
                <Link to="/awards">
                  How to participate in the Scholastic Awards<br></br>
                </Link>

                <Link to="/programs">
                  Programs for students and educators<br></br>
                </Link>

                <Link to="/gallery">
                  Visit our online gallery<br></br>
                </Link>

                <Link to="/support">
                  Support the Awards<br></br>
                </Link>

                <Link to="/regions">
                  Find your region<br></br>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Search

export const pageQuery = graphql`
  query SearchQuery {
    site {
      siteMetadata {
        author
        description
        title
      }
    }
    localSearchPages {
      index
      store
    }
  }
`
